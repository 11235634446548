<script>
import axios from "axios";
import moment from 'moment-timezone';
let user = JSON.parse(localStorage.getItem("user"));

export default {
  data() {
    return {
      json_fields: {
        "Invoice Number": "invoice",
        Timestamp: "date",
        "Are you?": "are_you",
        "Sender's first and last name": "sender_fn",
        "Sender's phone number": "sender_phone",
        "Sender's address": "sender_address",
        "Sender's email": "sender_email",
        "Recipient's first and last name": "recipient_fn",
        "Recipient's email": "recipient_email",
        "Recipient's phone number": "recipient_phone",
        "Recipient's address": "recipient_address",
        "total weight": "total_weight",
        "shipping fee": "shipping_fee",
        "price service": "service_price",
        "total tax": "total_tax",
        subtotal: "subtotal",
        discount: "discount",
        "total changer": "total_changer",
        "service name": "service_name",
        "service opition": "service_opition",

        // 'Telephone 2' : {
        //     field: 'phone.landline',
        //     callback: (value) => {
        //         return `Landline Phone - ${value}`;
        //     }
        // },
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      columns: [
        {
          label: "Ngày tạo",
          field: "crt",
          // width: "160px",
        },
        {
          label: "Mã đơn",
          field: "invoice",
          // width: "120px",
        },
        {
          label: "Người gửi",
          field: "sender_fn",
        },
        {
          label: "Điện thoại",
          field: "sender_phone",
        },
        {
          label: "Dịch vụ",
          field: "service_name",
        },
          {
          label: "Doanh thu",
          field: "total_changer",
        },


         {
          label: "Xử lý",
          field: "user.username",
        },
         {
          label: "Ghi chú",
          field: "noted",
          tdClass: 'text-center'
        },
        {
          label: "Trạng thái",
          field: "status",
        },
        {
          label: "Hành động",
          field: "_id",
          width: "120px",
        },
      ],
      rows: [],
      user: user,
    };
  },
  async created() {
    if (user) {
      if (user.admin) {
        let { data } = await axios({
          url: process.env.VUE_APP_SERVER_URL + "/api/bill/list",
          method: "post",
          headers: {
            authorization: user.token,
          },
        });

        this.rows = data ? data : [];
      } else {
        let { data } = await axios({
          url: process.env.VUE_APP_SERVER_URL + "/api/user/bill",
          method: "post",
          headers: {
            authorization: user.token,
          },
        });
        this.rows = data ? data : [];
      }
    }
  },

  methods: {
    mySearchFn(row, col, cellValue, searchTerm){
      let search = searchTerm.toUpperCase();
      let rgx = new RegExp(search,"igmu");
      let _value = cellValue.toString();
      return _value.match(rgx);
    },

    renderName() {
      return moment().format("hh:mm DD-MM-YYYY") + "-billing.xls";
    },
    formatName(fn){
      return fn.toUpperCase()
    },
  
    handlerOrder(_id) {
      this.$router.push({
        name: "order-add",
        query: {
          id: _id,
        },
      });
    },
      formatDate(_data) {
     return moment.tz(_data,'UTC').tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm');
    },
    detaild(_id) {
      this.$router.push({
        name: "bill-detaild",
        query: {
          id: _id,
        },
      });
    },
  },
};
</script>


<template>
  <div class="mb-5">
    <div>
      <h3 class="h5">Danh sách hóa đơn thanh toán</h3>
      <hr />
    </div>

    <div class="text-sm-end my-2">
      <export-excel
        class="btn btn-info btn-sm btn-default"
        :data="rows"
        :fields="json_fields"
        worksheet="billing list"
        :name="renderName()"
      >
        Download All
      </export-excel>
    </div>

    <vue-good-table

      :search-options="{
        enabled: true,
        trigger: 'enter',
        skipDiacritics: true,
        searchFn: mySearchFn,
        placeholder: 'Tìm kiếm theo dữ liệu nhập vào'
      }"
      :pagination-options="{
        enabled: true,
        rowsPerPageLabel: 'các mục trong trang',
        nextLabel: 'tiếp',
        prevLabel: 'trước',
        mode: 'records',
        allLabel: 'Tất cả'
      }"


      :line-numbers="true"
      :columns="columns"
      :rows="rows"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == '_id'">
          <button
            v-if="
              (props.row.user && props.row.user._id == user.id) ||
              user.admin == true
            "
            type="button"
            @click="detaild(props.formattedRow[props.column.field])"
            class="btn btn-primary btn-sm mx-2"
          >
            <i class="bx bx-edit-alt"></i>
          </button>

          <router-link
            :to="{
              path: '/hoa-don',
              query: {
                id: props.row.invoice,
              },
            }"
            target="_blank"
            class="btn btn-info btn-sm mx-2"
          >
            <i class="bx bx-show-alt"></i>
          </router-link>
        </span>

        <span v-else-if="props.column.field == 'crt'">
          {{ formatDate(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field == 'noted' && props.formattedRow[props.column.field] && props.formattedRow[props.column.field] != ''">
            <span class="text-danger text-center">*</span>
        </span>
  
        <span v-else-if="props.column.field == 'sender_fn'">
          {{ formatName(props.formattedRow[props.column.field]) }}
        </span>

         <span v-else-if="props.column.field == 'user.username'">
          {{ formatName(props.formattedRow[props.column.field]) }}
        </span>


        <span v-else-if="props.column.field == 'status'">
          <button
            v-if="props.formattedRow[props.column.field] == 'false'"
            class="btn btn-sm btn-danger"
            type="button"
          >
            Đơn bị hủy
          </button>

          <button
            v-if="props.formattedRow[props.column.field] == 'null'"
            type="button"
            class="btn btn-sm btn-info"
          >
            Đang vận chuyển
          </button>

          <button
            v-if="props.formattedRow[props.column.field] == 'true'"
            type="button"
            class="btn btn-sm btn-success"
          >
            Đã hoàn thành
          </button>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

