<script>
import axios from "axios";

import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Billing from "@/components/billing/list"
let user = JSON.parse(localStorage.getItem("user"));
/**
 * Register component
 */
export default {
  page: {
    title: "Danh sách khách hàng",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Billing
  }
};
</script>

<template>
  <Layout>
    <PageHeader />
    
    <Billing/>
    <!-- end row -->
  </Layout>
</template>